<template>
    <div id="confirmation" uk-modal class="uk-modal uk-open" style="display: flex; justify-content: center; align-items: center; opacity: 1;">
        <div class="uk-modal-dialog uk-modal-body">
            <h2 class="uk-modal-title">Confirmación de compra</h2>
            <p>Te hemos enviado a tu correo <span class="uk-text-italic uk-text-muted">{{showEmailPartially()}}</span> un código, debes escribir el código para confirmar tu compra.</p>
            <div class="uk-margin">
                <div class="uk-form-controls">
                    <input v-model="code" class="uk-input" id="form-stacked-confirmation" type="text" placeholder="Código de confirmación" maxlength="4">
                </div>
            </div>
            <div class="uk-margin" v-if="invalid">
                <div class="uk-alert-danger" uk-alert>
                    <a class="uk-alert-close" uk-close></a>
                    <p>El código de confirmación no es válido.</p>
                </div>
            </div>
            <p class="uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" @click="onHideConfirmation()">Cancelar</button>
                <button class="uk-button uk-button-primary" type="button" @click="onSendConfirmation()">Confirmar</button>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PurchaseConfirmationCode',
    props: {
        email: {
            type: String,
            default: 'juan@ejemplo.co'
        },
        invalid: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        console.log("invalid", this.invalid)
    },
    data() {
        return {
            code: ''
        }
    },
    methods: {
        showEmailPartially() {
            return `${this.email.substr(0,3)}...${(this.email.includes('@') ? '@' + this.email.split('@')[1] : '')}`
        },
        onSendConfirmation() {
            this.$emit('handler-send-purchase-confirmation', this.code)
        },
        onHideConfirmation() {
            this.$emit('handler-hide-purchase-confirmation')
        }
    }
}
</script>
