<template>
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: true">

      <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m">
          <li>
              <img
                class="image-cover"
                src="https://images.unsplash.com/photo-1612955314650-1f2b3b269d14?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80"
                alt="">
              <div class="uk-position-center uk-panel slot-info">
                  <div>
                    <span class="slider-title">Bonsáis</span>
                  </div>
                  <button class="uk-button uk-button-danger shop-now">COMPRA AHORA</button>
              </div>
          </li>
          <li>
            <div class="uk-cover-container">
              <img
                class="image-cover"
                src="https://images.unsplash.com/photo-1535222919969-4548310d5955?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
                alt="">
                <div class="uk-position-center uk-panel slot-info">
                  <div>
                    <span class="slider-title">Girasoles</span>
                  </div>
                  <button class="uk-button uk-button-danger shop-now">COMPRA AHORA</button>
                </div>
            </div>
          </li>
          <li>
              <img
                class="image-cover"
                src="https://images.unsplash.com/photo-1626795820612-f7d359c39d66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"
                alt="">
              <div class="uk-position-center uk-panel slot-info">
                  <div>
                    <span class="slider-title">Orquídeas</span>
                  </div>
                  <button class="uk-button uk-button-danger shop-now">COMPRA AHORA</button>
              </div>
          </li>
      </ul>

      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

    </div>
</template>

<script>
export default {
    name: 'Slider'
}
</script>

<style>
.image-cover {
    width: 100%;
    height: 550px;
    object-fit: cover;
    opacity: 0.7;
}

.slot-info {
    top: 225px;
    left: 80%;
}

.slider-title {
    font-size: 480%;
    font-weight: bold;
		color: white;
		text-shadow: 2px 2px 20px black;
}

.shop-now {
    font-size: 1.25rem;
}

@media (max-width: 920px) {
    .slot-info {
        top: 275px;
        left: 50%;
    }
}
</style>