<template>
    <div class="uk-flex uk-flex-center uk-flex-column uk-margin-xlarge-top uk-margin-xlarge-bottom">
        <div class="uk-flex uk-flex-center">
            <div class="uk-padding-small">
                <img src="../../assets/canceled.png" alt="">
            </div>
            <div class="uk-padding-small">
                <h2 class="uk-text-danger">Compra no completada</h2>
            </div>
        </div>
        <div class="uk-flex uk-flex-center">
            <p class="uk-text-danger">No fue posible completar la compra, debido a que no se ingresó un código de confirmación válido.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PurchaseCanceled',
    mounted() {
        this.$emit('handler-reset')
    }
}
</script>

<style>

</style>