<template>
  <div class="header-wrapper">
    <div class="header-menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div>
      <a @click="goToHome()"><img class="logo" src="../../assets/logo.png" alt=""></a>
    </div>
    <div class="header-menu">
      <div class="menu">
        <ul>
          <li>
            <a class="header-wrapper-active-option" @click="goToHome()">Inicio</a>
          </li>
          <li>
            <a>Ofertas</a>
          </li>
          <li>
            <a>Plantas</a>
          </li>
          <li>
            <a>Productos</a>
          </li>
          <li>
            <a>Accesorios</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="header-actions">
      <div class="menu">
        <ul>
          <li>
            <a @click="showEnteForm()">
              <img src="https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male3-26.png" alt="">
            </a>
          </li>
          <li>
            <a>
              <img src="https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-26.png" alt="">
            </a>
          </li>
          <li>
            <a>
              <img src="https://cdn3.iconfinder.com/data/icons/sympletts-free-sampler/128/heart-26.png" alt="">
              <span class="indicator">0</span>
            </a>
          </li>
          <li>
            <a @click="goToCheckout()">
              <img src="https://cdn1.iconfinder.com/data/icons/essential-21/129/Cart-26.png" alt="">
              <span class="indicator">{{productsCount}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
      productsCount: {
        type: Number,
        default: 0
      }
    },
    methods: {
        goToHome() {
          this.$emit('handler-show-home')
        },
        goToCheckout() {
          this.$emit('handler-show-checkout')
        },
        showEnteForm() {
          this.$emit('handler-show-ente-form')
        }
    }
}
</script>

<style>
.header-wrapper {
    position: static;
    top: 43px;
    width: 100%;
    height: 120px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.header-wrapper .header-menu-toggle > div {
    display: none;
    width: 25px;
    height: 2.2px;
    background-color: rgb(124, 122, 122);
    margin: 6px 0;
}

.header-wrapper .menu ul {
    display: flex;
    width: 100%;
    margin: 0;
}

.header-wrapper .menu ul li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-wrapper .menu ul li a {
    color: rgba(39, 37, 37, 0.95);
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    transition: all 0.3s ease;
}

.header-wrapper .menu ul li a:hover {
    color: #F86B73;
}

.header-wrapper-active-option {
    color: #F86B73 !important;
}

.header-actions .menu ul {
    display: flex;
    width: 100%;
    margin: 0;
}

.header-actions .menu ul li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-actions .menu ul li a {
    position: relative;
    color: rgba(39, 37, 37, 0.95);
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    transition: all 0.3s ease;
}

.header-actions .menu ul li a:hover {
    color: #F86B73;
}

.header-actions .menu ul li a .indicator {
    position: absolute;
    font-size: 11px;
    color: white;
    background-color: #F86B73;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 17px;
    border-radius: 50%;
    right: 0px;
    top: -8px;
}

@media (max-width: 1250px) {
    .header-wrapper {
        display: flex;
        justify-content: space-between;
        padding-left: 1%;
        padding-right: 1%;
    }
    .header-wrapper .logo {
        width: 180px;
    }
    .header-wrapper .menu ul li a {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 950px) {
    .header-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .header-wrapper .logo {
        width: 180px;
    }
    .header-wrapper .header-menu {
        display: none;
    }
    .header-wrapper .header-menu-toggle > div {
        display: block;
    }
    .header-actions .menu ul {
        padding-left: 0px;
    }
}

@media (max-width: 675px) {
    .header-actions .menu ul li:nth-child(2) {
        display: none;
    }
    .header-actions .menu ul li:nth-child(3) {
        display: none;
    }
}
</style>