<template>
    <div class="uk-margin-small-top uk-margin-small-bottom">
        <h3 class="subtitle">FORMA DE PAGO</h3>
    </div>
    <!-- Formulario de forma de pago -->
    <form class="uk-form-stacked" uk-grid autocomplete="off">
        <!-- Nombre de la tarjeta -->
        <div class="uk-margin uk-margin-remove-bottom uk-width-1-1@s">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-cardname">Nombre en la tarjeta</label>
            <div class="uk-form-controls">
                <input v-model="cardData.ownerName.value" :class="{'uk-form-danger': cardData.ownerName.isInvalid}" class="uk-input" id="form-stacked-cardname" type="text" placeholder="" @keyup="onValidate()">
            </div>
        </div>
        <!-- Número de la tarjeta -->
        <div class="uk-margin uk-margin-remove-bottom uk-width-1-1@s">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-cardnumber">Número de la tarjeta</label>
            <div class="uk-form-controls">
                <input v-model="cardData.number.value" :class="{'uk-form-danger': cardData.number.isInvalid}" class="uk-input" id="form-stacked-cardnumber" type="text" placeholder="Número de la tarjeta" @keypress="$root.onlyNumber($event);" @keyup="onValidate()" maxlength="19">
            </div>
        </div>
        <!-- Fecha de expiración -->
        <div class="uk-margin uk-margin-remove-bottom uk-width-1-3@s">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-expirationdate">Fecha de expiración</label>
            <div class="uk-form-controls">
                <input v-model="cardData.expirationData.value" :class="{'uk-form-danger': cardData.expirationData.isInvalid}" class="uk-input uk-form-width-small" id="form-stacked-expirationdate" type="text" placeholder="MM/AA" @keypress="$root.onlyNumber($event);" @keyup="$root.validateExpirationCard()" maxlength="5">
            </div>
        </div>
        <!-- Código de verificación -->
        <div class="uk-margin uk-margin-remove-bottom uk-width-1-3@s">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-verificationcode">Código de verificación</label>
            <div class="uk-form-controls">
                <input v-model="cardData.verificationCode.value" :class="{'uk-form-danger': cardData.verificationCode.isInvalid}" class="uk-input uk-form-width-small" id="form-stacked-verificationcode" type="text" placeholder="Número" @keypress="$root.onlyNumber($event);" @keyup="onValidate()" maxlength="4">
            </div>
        </div>
        <!-- No. cuotas -->
        <div class="uk-margin uk-margin-remove-bottom uk-width-1-1@s">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-text">No. cuotas</label>
            <select v-model="cardData.installmentNumber.value" :class="{'uk-form-danger': cardData.installmentNumber.isInvalid}" class="uk-select uk-form-width-small">
                <option v-for="ins in installments" :key="ins">{{ins}}</option>
            </select>
        </div>
    </form>
</template>

<script>
export default {
    name: 'PaymentMethod',
    props: {
        cardData: {
            type: Object,
            default() { return {} }
        }
    },
    data() {
        return {
            installments: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
        }
    },
    methods: {
        onValidate() {
            this.$emit('handler-validate')
        }
    }
}
</script>

<style>

</style>