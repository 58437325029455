<template>
    <div class="uk-flex uk-flex-center uk-flex-column uk-margin-xlarge-top uk-margin-xlarge-bottom">
        <div class="uk-flex uk-flex-center">
            <div class="uk-padding-small">
                <img src="../../assets/success.png" alt="">
            </div>
            <div class="uk-padding-small">
                <h2>¡Muchas gracias por tu compra!</h2>
            </div>
        </div>
        <div class="uk-flex uk-flex-center">
            <p>Recibirás tu pedido en 24 horas, puedes hacer seguimiento desde <a class="uk-text-bold">aquí</a> con el código que te ha llegado al correo electrónico.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PurchaseSuccess',
    mounted() {
        this.$emit('handler-reset')
    }
}
</script>

<style>

</style>