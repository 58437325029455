<template>
    <div id="my-id" uk-modal class="uk-modal uk-open" style="display: flex; justify-content: center; align-items: center; opacity: 1;">
        <div class="loadingio-spinner-ripple-t2f2jyottxl">
            <div class="ldio-sxv1ks0arq">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading'
}
</script>

<style>
.modal-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes ldio-sxv1ks0arq {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}.ldio-sxv1ks0arq div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-sxv1ks0arq 1s cubic-bezier(0,0.2,0.8,1) infinite;
}.ldio-sxv1ks0arq div:nth-child(1) {
  border-color: #79f704;
  animation-delay: 0s;
}
.ldio-sxv1ks0arq div:nth-child(2) {
  border-color: #07d5ec;
  animation-delay: -0.5s;
}
.loadingio-spinner-ripple-t2f2jyottxl {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-sxv1ks0arq {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-sxv1ks0arq div { box-sizing: content-box; }
</style>