<template>
    <div class="uk-margin-small-top">
        <h3 class="subtitle">DETALLE DE LA ORDEN</h3>
    </div>
    <!-- Columnas de PRODUCTO y SUBTOTAL pertenecientes al carrito de compras -->
    <div class="uk-flex uk-flex-between background-light uk-panel uk-padding-small border-bottom-default">
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">PRODUCTO</h5></div>
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">SUBTOTAL</h5></div>
    </div>
    <!-- Bloque para mostrar los productos (nombre y subtotal para cada producto) -->
    <div class="background-light uk-panel uk-padding-small border-bottom-default">
        <div v-for="p in shoppingCart" :key="p.sku" class="uk-flex uk-flex-between uk-margin-small-bottom">
            <div><span class="uk-margin-remove-bottom">{{p.title}} <b>x {{p.quantity}}</b></span></div>
            <div><h5 class="uk-margin-remove-bottom uk-text-bold">$ {{$root.thousandPointsFormatter(getSubtotalByProduct(p))}}</h5></div>
        </div>
    </div>
    <!-- Bloque para mostrar el costo del subtotal de la compra -->
    <div class="uk-flex uk-flex-between background-light uk-panel uk-padding-small border-bottom-default">
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">SUBTOTAL</h5></div>
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">$ {{$root.thousandPointsFormatter(getSubtotal(shoppingCart))}}</h5></div>
    </div>
    <!-- Bloque para mostrar el costo del envío -->
    <div class="uk-flex uk-flex-between background-light uk-panel uk-padding-small border-bottom-default">
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">ENVÍO</h5></div>
        <div><h5 class="uk-margin-remove-bottom uk-text-bold uk-text-success">Gratis</h5></div>
    </div>
    <!-- Bloque para mostrar el total de la compra -->
    <div class="uk-flex uk-flex-between background-light uk-panel uk-padding-small border-bottom-default">
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">TOTAL</h5></div>
        <div><h5 class="uk-margin-remove-bottom uk-text-bold">$ {{$root.thousandPointsFormatter(getTotal(shoppingCart))}}</h5></div>
    </div>
    <!-- Acción de realizar pedido -->
    <div class="uk-margin">
        <button
            :disabled="shoppingCart.length === 0"
            @click="onPlaceOrder()"
            class="uk-button uk-button-danger uk-width-1-1 uk-text-default uk-text-bold padding-vertical-small"
        >
            REALIZAR PEDIDO
        </button>
    </div>
</template>

<script>
export default {
    name: 'OrderDetail',
    props: {
        shoppingCart: {
            type: Array,
            default() { return [] }
        }
    },
    methods: {
        getSubtotalByProduct(product) {
            if (!product)
                return 0

            return product.quantity * product.price
        },
        getSubtotal(products) {
            if (!products || products.length == 0)
                return 0

            return products.map(x => x.price * x.quantity).reduce((a,b) => a + b)
        },
        getTotal(products) {
            return this.getSubtotal(products)
        },
        onPlaceOrder() {
            this.$emit('handler-place-order')
        }
    }
}
</script>
