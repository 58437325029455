<template>
    <div id="product-detail">
        <div class="image-set">
            <img :src="product.image" alt="">
        </div>
        <div class="product-info">
            <div>
                <div class="uk-margin-small-top">
                    <h1><b>{{product.title}}</b></h1>
                </div>
                <hr>
                <div class="uk-margin-small-top">
                    <ul class="ul-customized">
                        <li><span>SKU: {{product.sku}}</span></li>
                        <li>Categoría: {{product.category}}</li>
                        <li>Etiquetas: {{product.tags}}</li>
                    </ul>
                </div>
                <div class="uk-margin-small-top">
                    <p class="uk-text-justify">{{product.longDescription}}</p>
                </div>
                <div class="uk-margin-small-top">
                    <h2>$ {{$root.thousandPointsFormatter(product.price)}}</h2>
                </div>
                <div class="uk-margin-small-top">
                    <button class="uk-button uk-button-secondary btn-counter" @click="onDecrease(product)">-</button>
                    <button class="uk-button uk-button-secondary btn-counter btn-counter-medium">{{product.quantity}}</button>
                    <button class="uk-button uk-button-secondary btn-counter" @click="onIncrease(product)">+</button>
                </div>
                <div class="uk-margin-medium-top" @click="onAddToCart(product)">
                    <button class="uk-button uk-button-danger btn-counter uk-flex uk-flex-center">
                        <span uk-icon="icon: cart; ratio: 2"></span>
                        Agregar al carrito
                    </button>
                </div>
                <div v-if="addedProductNotification" class="uk-margin-medium-top">
                    <div class="uk-alert-success" uk-alert>
                        <a class="uk-alert-close" uk-close></a>
                        <p>{{addedProductNotification}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductDetail',
    props: {
        product: Object,
        addedProductNotification: String
    },
    methods: {
        onDecrease(obj) {
            this.$emit('handler-decrease', obj)
        },
        onIncrease(obj) {
            this.$emit('handler-increase', obj)
        },
        onAddToCart(obj) {
            this.$emit('handler-add-to-cart', obj)
        }
    }
}
</script>

<style>
#product-detail {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

#product-detail .image-set {
    width: 550px;
}

#product-detail .image-set > img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    box-shadow:
       inset 0 -3em 3em rgba(0,0,0,0.1),
             0 0  0 2px rgb(255,255,255),
             0.3em 0.3em 1em rgba(0,0,0,0.3);
}

#product-detail .product-info {
    width: 700px;
    margin-left: 20px;
}

.btn-counter {
    font-size: 20px;
    margin-right: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-counter-medium {
    padding-left: 25px;
    padding-right: 25px;
}

@media (max-width: 1332px) {
    #product-detail {
        display: flex;
        flex-direction: row;
    }
    #product-detail .image-set {
        width: 450px;
    }
    #product-detail .image-set > img {
        height: 500px;
    }
}

@media (max-width: 1202px) {
    #product-detail {
        display: flex;
        flex-direction: row;
    }
    #product-detail .image-set {
        width: 400px;
    }
    #product-detail .image-set > img {
        height: 450px;
    }
}

@media (max-width: 1162px) {
    #product-detail {
        display: flex;
        flex-direction: row;
    }
    #product-detail .image-set {
        width: 400px;
    }
    #product-detail .image-set > img {
        height: 510px;
    }
    #product-detail .product-info {
        width: 500px;
        margin-left: 20px;
    }
}

@media (max-width: 957px) {
    #product-detail {
        display: flex;
        flex-direction: column;
    }
    #product-detail .image-set {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
    #product-detail .image-set > img {
        height: 400px;
    }
    #product-detail .product-info {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
}
</style>