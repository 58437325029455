<template>
    <div class="uk-container uk-margin-small-top uk-margin-small-bottom">
      <div class="uk-text-center">
        <h3 class="title">CHECKOUT</h3>
      </div>
      <div id="checkout">
          <div class="checkout-form uk-margin-small-left uk-margin-small-right">
              <CustomerInformation
                :customer-data="purchase.customerData"

                @handler-validate="handlerValidate"
              />
              <PaymentMethod
                :card-data="purchase.cardData"

                @handler-validate="handlerValidate"
              />
          </div>
          <div class="checkout-order uk-background-muted uk-padding-small">
              <OrderDetail
                :shopping-cart="shoppingCart"

                @handler-place-order="handlerPlaceOrder"
              />
          </div>

      </div>
    </div>

    <PurchaseConfirmationCode
        v-if="confirmationCode.required"
        :invalid="confirmationCode.invalid"

        @handler-send-purchase-confirmation="handlerSendPurchaseConfirmation"
        @handler-hide-purchase-confirmation="handlerHidePurchaseConfirmation"
    />
</template>

<script>
import CustomerInformation from './CustomerInformation.vue'
import OrderDetail from './OrderDetail.vue'
import PaymentMethod from './PaymentMethod.vue'
import PurchaseConfirmationCode from '../Purchase/ConfirmationCode.vue'
export default {
    name: 'Checkout',
    components: {
        CustomerInformation,
        OrderDetail,
        PaymentMethod,
        PurchaseConfirmationCode
    },
    props: {
        shoppingCart: {
            type: Array,
            default() { return [] }
        },
        purchase: {
            type: Object,
            default() {
                return {
                    customerData: {},
                    cardData: {}
                }
            }
        },
        confirmationCode: {
            type: Object,
            default() {
                return {
                    required: false,
                    invalid: false
                }
            }
        }
    },
    mounted() {
        this.handlerValidate()
        console.log(this.confirmationCode)
    },
    methods: {
        handlerPlaceOrder() {
            this.$emit('handler-place-order')
        },
        handlerValidate() {
            this.$emit('handler-validate')
        },
        handlerSendPurchaseConfirmation(val) {
            this.$emit('handler-send-purchase-confirmation', val)
        },
        handlerHidePurchaseConfirmation() {
            this.$emit('handler-hide-purchase-confirmation')
        }
    }
}
</script>

<style>
#checkout {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: row;
}

#checkout .checkout-form {
    width: 550px;
}

#checkout .checkout-order {
    width: 450px;
}

@media (max-width: 702px) {
    #checkout {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #checkout .checkout-form {
        width: 90%;
    }    
    #checkout .checkout-order {
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
</style>