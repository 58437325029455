<template>
    <div class="uk-margin-small-top uk-margin-small-bottom">
        <h3 class="subtitle">INFORMACIÓN DEL CLIENTE</h3>
    </div>
    <!-- Formulario de información del cliente -->
    <form class="uk-form-stacked" autocomplete="off">
        <!-- Nombre completo -->
        <div class="uk-margin">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-fullname">Nombre completo</label>
            <div class="uk-form-controls">
                <input v-model="customerData.fullname.value" :class="{'uk-form-danger': customerData.fullname.isInvalid}" class="uk-input" id="form-stacked-fullname" type="text" placeholder="" @keyup="onValidate()">
            </div>
        </div>
        <!-- No. Identificación -->
        <div class="uk-margin">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-id">No. Identificación</label>
            <div class="uk-form-controls">
                <input v-model="customerData.identification.value" :class="{'uk-form-danger': customerData.identification.isInvalid}" class="uk-input" id="form-stacked-id" type="text" placeholder="" @keypress="$root.onlyNumber($event);" @keyup="onValidate()" maxlength="20">
            </div>
        </div>
        <!-- No. Telefónico -->
        <div class="uk-margin">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-phone">No. Telefónico</label>
            <div class="uk-form-controls">
                <input v-model="customerData.phoneNumber.value" :class="{'uk-form-danger': customerData.phoneNumber.isInvalid}" class="uk-input" id="form-stacked-phone" type="text" placeholder="" @keypress="$root.onlyNumber($event);" @keyup="onValidate()" maxlength="10">
            </div>
        </div>
        <!-- Correo electrónico -->
        <div class="uk-margin">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-email">Correo electrónico</label>
            <div class="uk-form-controls">
                <input v-model="customerData.email.value" :class="{'uk-form-danger': customerData.email.isInvalid}" class="uk-input" id="form-stacked-email" type="email" placeholder="" @keyup="$root.validateEmail()">
            </div>
        </div>
        <!-- Dirección -->
        <div class="uk-margin">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-address">Dirección</label>
            <div class="uk-form-controls">
                <input v-model="customerData.address.value" :class="{'uk-form-danger': customerData.address.isInvalid}" class="uk-input" id="form-stacked-address" type="text" placeholder="" @keyup="onValidate()">
            </div>
        </div>
        <!-- Ciudad -->
        <div class="uk-margin">
            <label class="uk-form-label uk-text-bold uk-text-muted" for="form-stacked-city">Ciudad</label>
            <div class="uk-form-controls">
                <input v-model="customerData.city.value" :class="{'uk-form-danger': customerData.city.isInvalid}" class="uk-input" id="form-stacked-city" type="text" placeholder="" @keyup="onValidate()">
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'CustomerInformation',
    props: {
        customerData: {
            type: Object,
            default() { return {} }
        }
    },
    methods: {
        onValidate() {
            this.$emit('handler-validate')
        }
    }
}
</script>
