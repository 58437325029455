<template>
  <div class="footer">
    <div id="main-footer">
      <div>
        <h4 class="text-white">AYUDA</h4>
        <ul>
          <li>Contáctenos</li>
          <li>Política de Envíos</li>
          <li>Cambios y Devoluciones</li>
          <li>Preguntas Frecuentes</li>
          <li>Restricciones Generales</li>
          <li></li>
        </ul>
      </div>
      <div>
        <h4 class="text-white">INFO SITIO</h4>
        <ul>
          <li>Sobre Nosotros</li>
          <li>Política de Privacidad</li>
          <li>Términos y Condiciones</li>
          <li>Política de Tratamiento de Información</li>
          <li>Politica de Garantía</li>
        </ul>
      </div>
    </div>
    <hr class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-medium-left uk-margin-medium-right text-opaque">
    <div id="social-footer">
      <div>
        <a>
          <img src="../../assets/social/facebook.png" alt="">
        </a>
      </div>
      <div class="uk-margin-small-left">
        <a>
          <img src="../../assets/social/twitter.png" alt="">
        </a>
      </div>
      <div class="uk-margin-small-left">
        <a>
          <img src="../../assets/social/instagram.png" alt="">
        </a>
      </div>
      <div class="uk-margin-small-left">
        <a>
          <img src="../../assets/social/youtube.png" alt="">
        </a>
      </div>
    </div>
    <div id="legal-footer">
      <div>
        <a>
          <span class="text-white">Terms &amp; Conditions</span>
        </a>
        <a>
          <span class="uk-margin-small-left text-white">Privacy Policy</span>
        </a>
      </div>
      <div>
        <span>&copy; 2021 Copyright <b>Plantas S.A.</b></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>
.footer {
    position: static;
    top: 663px;
    width: 100%;
    height: auto;
    background-color: #555;
    color: #bbb;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
}

.footer #main-footer {
    display: flex;
    justify-content: space-evenly;
    padding-top: 15px;
    padding-bottom: 15px;    
}

.footer #main-footer ul {
    padding-left: 0;
}

.footer #main-footer * ul > li {
    margin-bottom: 7px;
    color: rgb(255, 255, 255, 0.8);
}

.footer #social-footer {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.footer #legal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    background: #333;
}

.footer #legal-footer * a:hover {
    text-decoration: 1px underline white;
}

@media (max-width: 650px) {
    .footer #main-footer {
        display: flex;
        flex-direction: column;
        margin-left: 25%;
    }
    .footer #legal-footer {
        display: flex;
        flex-direction: column;
    }
}
</style>