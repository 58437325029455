<template>

  <TopBar />

  <HeaderTag
    :products-count="getProductsCountInCart(shoppingCart)"

    @handler-show-home="handlerShowHome"
    @handler-show-checkout="handlerShowCheckout"
    @handler-show-ente-form="handlerShowEnteForm"
  />

  <div class="page-content">
    <Home
      v-if="view === 'HOME'"
      :products="products"

      @handler-show-product-detail="handlerShowProductDetail"
    />
    <ProductDetail
      v-else-if="view === 'PRODUCT_DETAIL'"
      :product="product"
      :added-product-notification="addedProductNotification"

      @handler-decrease="handlerDecrease"
      @handler-increase="handlerIncrease"
      @handler-add-to-cart="handlerAddToCart"
    />
    <Checkout
      v-else-if="view === 'CHECKOUT'"
      :shopping-cart="shoppingCart"
      :purchase="purchase"
      :confirmation-code="confirmationCode"

      @handler-place-order="handlerPlaceOrder"
      @handler-validate="validate"
      @handler-send-purchase-confirmation="handlerSendPurchaseConfirmation"
      @handler-hide-purchase-confirmation="handlerHidePurchaseConfirmation"
    />
    <PurchaseSuccess
      v-else-if="view === 'PURCHASE_SUCCESS'"

      @handler-reset="handlerReset"
    />
    <PurchaseCanceled
      v-else-if="view === 'PURCHASE_CANCELED'"

      @handler-reset="handlerReset"
    />

  </div>

  <Footer />

  <EnteForm
    v-if="verifico.config.ente.isFormVisible"
    :code="verifico.config.ente.value"

    @handler-save-ente-code="handlerSaveEnteCode"
    @handler-hide-ente-form="handlerHideEnteForm"
  />

  <Loading v-if="requesting" />

</template>

<script>
import ProductsJSON from "./assets/products.json"
import TopBar from './components/Views/TopBar.vue'
import HeaderTag from './components/Views/Header.vue'
import Home from './components/Home/index.vue'
import ProductDetail from './components/Product/Detail.vue'
import Checkout from './components/Checkout/index.vue'
import Footer from './components/Views/Footer.vue'
import PurchaseSuccess from './components/Purchase/Success.vue'
import PurchaseCanceled from './components/Purchase/Canceled.vue'
import EnteForm from './components/Views/EnteForm.vue'
import Loading from './components/Views/Loading.vue'
/**
 * Library verifico/web-api 
 */
import {
  Cliente,
  Evento,
  Variables,
  VariableDispositivo,
  VariableAdquisicion,
  VariablePago,
  VariablePrecio,
  VariableTarjetaDePago,
  VariableReceptorDeFacturacion,
  VariableDestinatarioDeEnvio,
  VariableDireccionPostal
} from '@verifico/web-api'

export default {
  name: 'App',
  components: {
    TopBar,
    HeaderTag,
    Home,
    ProductDetail,
    Checkout,
    Footer,
    PurchaseSuccess,
    PurchaseCanceled,
    EnteForm,
    Loading
  },
  data() {
    return {
      view: 'HOME',
      requesting: false,
      purchase: {
        customerData: {
          fullname: {
            value: '',
            isInvalid: false
          },
          identification: {
            value: '',
            isInvalid: false
          },
          phoneNumber: {
            value: '',
            isInvalid: false
          },
          email: {
            value: '',
            isInvalid: false
          },
          address: {
            value: '',
            isInvalid: false
          },
          city: {
            value: '',
            isInvalid: false
          }
        },
        cardData: {
          ownerName: {
            value: '',
            isInvalid: false
          },
          number: {
            value: '',
            isInvalid: false
          },
          expirationData: {
            value: '',
            isInvalid: false
          },
          verificationCode: {
            value: '',
            isInvalid: false
          },
          installmentNumber: {
            value: 1,
            isInvalid: false
          }
        }
      },
      product: {
        image: '',
        title: '',
        shortDescription: '',
        longDescription: '',
        price: 0,
        quantity: 1,
        sku: '',
        category: '',
        tags: ''
      },
      products: ProductsJSON,
      shoppingCart: [],
      addedProductNotification: '',
      confirmationCode: {
        required: false,
        invalid: false,
        failedAttemps: 0,
        identificador: undefined
      },
      verifico: {
        client: null,
        config: {
          key: '&#98;&#56;&#99;&#100;&#49;&#52;&#100;&#100;&#101;&#100;&#98;&#97;&#98;&#56;&#54;&#56;&#57;&#50;&#54;&#97;&#50;&#50;&#55;&#53;&#56;&#99;&#97;&#49;&#99;&#99;&#50;&#49;&#101;&#49;&#102;&#102;&#54;&#53;&#53;&#97;&#51;&#100;&#52;&#56;&#53;&#55;&#56;&#50;&#50;&#97;',
          apiURL: '&#104;&#116;&#116;&#112;&#115;&#58;&#47;&#47;&#117;&#115;&#45;&#99;&#101;&#110;&#116;&#114;&#97;&#108;&#49;&#45;&#110;&#117;&#98;&#101;&#45;&#104;&#101;&#120;&#97;&#103;&#111;&#110;&#97;&#108;&#46;&#99;&#108;&#111;&#117;&#100;&#102;&#117;&#110;&#99;&#116;&#105;&#111;&#110;&#115;&#46;&#110;&#101;&#116;&#47;&#97;&#112;&#105;&#47;&#118;&#49;',
          grupoDeEntes: 'mis-usuarios',
          ente: {
            value: '8934',
            isFormVisible: false
          }
        }
      }
    }
  },
  mounted() {
    this.initialize()
  },
  watch: {
    'purchase.cardData.expirationData.value': {
      handler: function(val) {
        this.expirationCardPrinter(val)
      },
      deep: true
    }
  },
  methods: {
    initialize() {
      this.setupAPI()
      this.setEnteCode()
    },
    setupAPI() {
      this.verifico.client = new Cliente(this.de(this.verifico.config.key))
      this.verifico.client.URLAPI = this.de(this.verifico.config.apiURL)
    },
    setEnteCode() {
      this.verifico.config.ente.value = (localStorage.enteCode) ? localStorage.enteCode : '8934'
    },
    setView(v) {
      this.view = v
    },
    getProductsCountInCart(arr) {
      if (!arr || arr.length === 0) {
        return 0
      }

      let count = arr.reduce((a, b) => ({quantity: a.quantity + b.quantity})).quantity

      return count
    },
    thousandPointsFormatter(x) {
        if (x === undefined) return
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    handlerShowHome() {
      this.setView('HOME')
    },
    handlerShowProductDetail(obj) {
      this.product = obj
      this.setView('PRODUCT_DETAIL')
    },
    handlerShowCheckout() {
      this.setView('CHECKOUT')
    },
    handlerDecrease(obj) {
      obj.quantity -= obj.quantity > 1 ? 1 : 0
    },
    handlerIncrease(obj) {
      obj.quantity += 1
    },
    handlerAddToCart(obj) {
      let index = 0
      let exists = this.shoppingCart.some((p, i) => {
        if (p.sku === obj.sku) {
          index = i
          return true
        }
        index++
      })

      if (!exists) {
        this.shoppingCart.push(obj)
        this.addedProductNotification = 'Producto agregado al carrito'
      } else {
        this.shoppingCart.splice(index, 1, obj)
        this.addedProductNotification = 'El producto ha sido actualizado en el carrito'
      }

      setTimeout(() => {
        this.addedProductNotification = ''
      }, 3000)

    },
    handlerShowEnteForm() {
      this.verifico.config.ente.isFormVisible = true
    },
    handlerHideEnteForm() {
      this.verifico.config.ente.isFormVisible = false
    },
    handlerSaveEnteCode(newCode) {
      this.verifico.config.ente.value = newCode
      localStorage.enteCode = newCode
    },
    handlerPlaceOrder() {
      if (!this.validate()) {
        return
      }

      this.sendOrderToAPI()
    },
    sendOrderToAPI() {
      this.requesting = true
      let dispositivo  = new VariableDispositivo()
      let total = this.shoppingCart.map(x => x.price * x.quantity).reduce((a,b) => a + b)
      let precio       = new VariablePrecio('COP', String(total))
      let tarjeta      = new VariableTarjetaDePago(this.purchase.cardData.expirationData.value, this.purchase.cardData.number.value)
      let pago         = new VariablePago(precio, tarjeta)
      let direccion    = new VariableDireccionPostal('CO', '', this.purchase.customerData.city.value, '', this.purchase.customerData.address.value)
      let receptor     = new VariableReceptorDeFacturacion(this.purchase.customerData.fullname.value, this.purchase.customerData.identification.value, this.purchase.customerData.phoneNumber.value, this.purchase.customerData.email.value, direccion)
      let destinatario = new VariableDestinatarioDeEnvio(this.purchase.customerData.fullname.value, this.purchase.customerData.phoneNumber.value, this.purchase.customerData.email.value, direccion)
      let adquisicion  = new VariableAdquisicion(pago, receptor, destinatario)

      let variables    = new Variables(undefined, dispositivo, adquisicion)

      let evento = new Evento(this.verifico.config.grupoDeEntes, this.verifico.config.ente.value, variables)

      this.verifico.client.registrarEvento(evento).then(res => {
        this.requesting = false
        if (res.verificacion.requerida) {
          this.confirmationCode.identificador = res.identificador
          this.confirmationCode.failedAttemps = 0
          this.confirmationCode.required = true
        } else {
          this.setView('PURCHASE_SUCCESS')
        }
      }).catch(err => {
        this.requesting = false
        console.log(err.message)
      })

    },
    handlerSendPurchaseConfirmation(code) {
      this.confirmationCode.invalid = false
      this.requesting = true

      this.verifico.client.verificarCodigoDeEvento(this.confirmationCode.identificador, code).then(veri => {
        this.requesting = false
        if (!veri.valido) {
          this.confirmationCode.failedAttemps += 1
          this.confirmationCode.invalid = true
          if (this.confirmationCode.failedAttemps >= 3) {
            this.setView('PURCHASE_CANCELED')
          }

          return
        }

        this.confirmationCode.required = false
        this.setView('PURCHASE_SUCCESS')
      }).catch(err => {
        this.requesting = false
        console.log(err.message)
      })

    },
    handlerHidePurchaseConfirmation() {
      this.confirmationCode.required = false
      this.confirmationCode.invalid = false
    },
    handlerReset() {
      this.purchase = {
        customerData: {
          fullname: {
            value: '',
            isInvalid: false
          },
          identification: {
            value: '',
            isInvalid: false
          },
          phoneNumber: {
            value: '',
            isInvalid: false
          },
          email: {
            value: '',
            isInvalid: false
          },
          address: {
            value: '',
            isInvalid: false
          },
          city: {
            value: '',
            isInvalid: false
          }
        },
        cardData: {
          ownerName: {
            value: '',
            isInvalid: false
          },
          number: {
            value: '',
            isInvalid: false
          },
          expirationData: {
            value: '',
            isInvalid: false
          },
          verificationCode: {
            value: '',
            isInvalid: false
          },
          installmentNumber: {
            value: 1,
            isInvalid: false
          }
        }
      }
      this.product = {
        image: '',
        title: '',
        shortDescription: '',
        longDescription: '',
        price: 0,
        quantity: 1,
        sku: '',
        category: '',
        tags: ''
      }
      this.confirmationCode = {
        required: false,
        invalid: false,
        failedAttemps: 0
      }
      this.shoppingCart = []
      this.products.forEach((v, i, obj) => {
        obj[i].quantity = 1
      })
    },
    validate() {
      let customerValidate = this.isCustomerFormValid()
      let cardValidate = this.isCardFormValid()

      return customerValidate && cardValidate
    },
    isCustomerFormValid() {
      let isInvalidForm = false
      Object.entries(this.purchase.customerData).forEach((obj) => {
        if (obj[0] === 'email') {
          this.validateEmail()
          return
        }
        this.purchase.customerData[obj[0]].isInvalid = !obj[1].value
        if (!obj[1].value) {
          isInvalidForm = true
        }
      })

      return !isInvalidForm
    },
    isCardFormValid() {
      let isInvalidForm = false
      Object.entries(this.purchase.cardData).forEach((obj) => {
        if (obj[0] === 'expirationData') {
          this.validateExpirationCard()
          return
        }
        this.purchase.cardData[obj[0]].isInvalid = !obj[1].value
        if (!obj[1].value) {
          isInvalidForm = true
        }
      })

      return !isInvalidForm
    },
    validateEmail() {
      const email = this.purchase.customerData.email.value
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.purchase.customerData.email.isInvalid = !res.test(String(email).toLowerCase())
    },
    validateExpirationCard() {
      const val = this.purchase.cardData.expirationData.value
      const res = val.replaceAll('/', '').length === 4
      this.purchase.cardData.expirationData.isInvalid = !res
    },
    onlyNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    expirationCardPrinter(v) {
      let val = v.replaceAll('/', '')
      val = val.length > 2 ? `${val.substr(0,2)}/${val.substr(2, val.length)}` : val
      this.purchase.cardData.expirationData.value = val;
    },
    de(str) {
      return str.replace(/&#(\d+);/g, function(match, dec) {
        return String.fromCharCode(dec);
      });
    }
  }
}
</script>
