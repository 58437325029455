<template>
  <div class="topbar">
    <div class="info">
      <span>Ordene en línea o contáctenos: (+57)3001234567</span>
    </div>
    <div class="menu">
      <ul>
        <li>
          <a>Acerca de nosotros</a>
        </li>
        <li class="vertical-line">
          <a>Contáctenos</a>
        </li>
        <li class="vertical-line">
          <a>PPFF</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TopBar'
}
</script>

<style>
.topbar {
    position: static;
    top: 0;
    width: 100%;
    height: 43px;
    background-color: #F86B73;
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
}

.topbar .info {
    color: rgba(255, 255, 255, 0.95);
    font-size: 95%;
}

.topbar .menu ul {
    display: flex;
    width: 100%;
    margin: 0;
}

.topbar .menu ul li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    line-height: 35px;
}

.topbar .menu ul li a {
    color: rgb(255, 255, 255, 0.95);
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none;
    font-size: 95%;
}

@media (max-width: 992px) {
    .topbar {
        display: flex;
        justify-content: center;
    }
    .topbar .menu ul {
        display: none;
    }
}
</style>