<template>
    <div id="ente" uk-modal class="uk-modal uk-open" style="display: flex; justify-content: center; align-items: center; opacity: 1;">
        <div class="uk-modal-dialog uk-modal uk-modal-body" style="width: 400px;">
            <h2 class="uk-modal-title">Usuario</h2>
            <div class="uk-margin">
                <div class="uk-form-controls">
                    <input v-model="code" class="uk-input" id="form-stacked-ente" type="text" placeholder="Código de usuario">
                </div>
            </div>
            <p class="uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button" @click="onHide()">Cerrar</button>
                <button :disabled="!code.trim()" class="uk-button uk-button-primary" type="button" @click="onSave()">Guardar cambios</button>
            </p>
            <div class="uk-margin" v-if="savedChanges">
                <div class="uk-alert-success" uk-alert>
                    <a class="uk-alert-close" uk-close></a>
                    <p>El código de usuario ha sido actualizado</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EnteForm',
    props: {
        code: {
            type: String,
            default: '8934'
        }
    },
    data() {
        return {
            savedChanges: false
        }
    },
    methods: {
        onSave() {
            this.$emit('handler-save-ente-code', this.code.trim())
            this.savedChanges = true
            setTimeout(
                () => this.savedChanges = false,
                2500
            )
        },
        onHide() {
            this.$emit('handler-hide-ente-form')
        }
    }
}
</script>
