<template>
  <div>
    <Slider />
    <Products 
        :products="products"

        @go-to-product-detail="goToProductDetail"
    />
  </div>
</template>

<script>
import Slider from '../Views/Slider'
import Products from '../Product/List.vue'
export default {
    name: 'Home',
    components: {
        Slider,
        Products
    },
    props: {
        products: {
            type: Array,
            default() { return [] }
        }
    },
    methods: {
        goToProductDetail(obj) {
            this.$emit('handler-show-product-detail', obj)
        }
    }
}
</script>