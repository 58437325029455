<template>
    <div class="uk-container uk-margin-medium-top uk-margin-small-bottom">
      <div class="uk-text-center">
        <h3 class="title">PRODUCTOS DESTACADOS</h3>
      </div>
      <div id="featured-products">
        <div class="product" v-for="(p, i) in products" :key="i">
          <a @click="goToProductDetail(p)">
            <img
              class="picture-cover" 
              :src="p.image" 
              :alt="p.title">
          </a>
          <div class="uk-text-center">
            <div class="uk-margin-small-top">
              <a @click="goToProductDetail(p)">
                <span class="uk-text-muted uk-text-small">{{p.title}}</span>
              </a>
            </div>
            <div class="uk-margin-small-top">
              <a @click="goToProductDetail(p)">
                <span class="uk-text-secondary">{{p.shortDescription}}</span>
              </a>
            </div>
            <div class="uk-margin-small-top">
              <span class="uk-text-secondary">{{p.price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Products',
    props: {
        products: Array
    },
    methods: {
        goToProductDetail(obj) {
            this.$emit('go-to-product-detail', obj)
        }
    }
}
</script>

<style>
#featured-products {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

#featured-products .product {
    width: 22%;
    height: auto;
    margin: 20px 10px 10px 20px;
		border: 1px solid #EEE;
}

#featured-products .product * img {
    width: 100%;
    height: 310px;
}

#featured-products .product * a:hover {
    text-decoration: none;
}

#featured-products .product:hover {
	box-shadow: 0px 0px 15px grey;
}

@media (max-width: 1097px) {
    #featured-products .product {
        width: 30%;
    }
}

@media (max-width: 998px) {
    #featured-products .product {
        width: 45%;
    }
}

@media (max-width: 676px) {
    #featured-products .product {
        width: 100%;
    }
}
</style>